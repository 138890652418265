export default function useAds() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();
  const searchStore = useSearchStore();

  /********************
   * REFS & VARS       *
   ********************/
  const routeBaseName = computed(() => {
    return getRouteBaseName(route);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function requestAds() {
    if (!window?.__consentIsReady) {
      setTimeout(() => requestAds(), 250);
      return;
    }
    if (window.__tcfapi) {
      _doRequestAds();
      return;
    }
    let counter = 0;
    const requestAdsInterval = setInterval(() => {
      counter++;
      if (window.__tcfapi) {
        _doRequestAds();
        clearInterval(requestAdsInterval);
        return;
      }
      if (counter > 50) {
        clearInterval(requestAdsInterval);
      }
    }, 100);
  }

  function _doRequestAds() {
    setTimeout(() => {
      if (!window.moli || !window.moli.requestAds) {
        return;
      }

      const pageType = SIMPLE_ROUTE_IDENTIFIERS[routeBaseName.value] || routeBaseName.value;

      if (window.moli.setTargeting) {
        window.moli.setTargeting('camping_page_type', pageType);
        window.moli.setTargeting('camping_country', searchStore.getLocationSlug('country'));
        window.moli.setTargeting('camping_state', searchStore.getLocationSlug('federal_state'));
        window.moli.setTargeting('camping_region', searchStore.getLocationSlug('region'));
      }

      if (window.moli.addLabel) {
        window.moli.addLabel(pageType);
      }

      try {
        window.moli.requestAds().catch(() => {});
      } catch (_e) { /* empty */ }
    }, 500);
  }

  return {
    requestAds,
  };
}
